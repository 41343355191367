import React from "react"

export const TitleUnderlineCovertionsOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="133" height="12" viewBox="0 0 133 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.789 10.1433C21.6048 9.38663 2.01367 11.3934 0.616724 11.2452C-0.622281 10.9863 0.270083 5.11208 1.23678 4.19113C1.57953 3.86379 2.02563 3.7772 3.09094 3.86751C4.02527 3.94586 10.467 3.48857 26.0506 2.47337C26.3553 2.64276 27.1281 2.00538 27.3103 2.21869C27.4493 2.34239 29.7123 2.29491 33.0766 2.1872C36.8516 2.06527 47.1284 1.64835 52.1966 1.48413C59.6767 1.23426 56.8633 1.27835 65.4264 1.06865C67.2177 1.02384 65.8176 1.39492 67.9241 1.24038C72.4167 0.916266 82.2349 1.18892 85.6353 0.897165C88.3626 0.662691 88.9567 0.649592 91.5763 0.883492C93.6719 1.06949 108.463 1.08242 110.134 0.735347C110.536 0.631734 110.842 0.683863 110.832 0.812231C111.007 0.824475 120.851 1.22571 120.894 1.33207C120.963 1.46092 121.259 1.53533 121.548 1.45335C122.073 1.31141 132.776 1.26006 132.922 1.64066C133.335 2.73762 131.498 7.22684 130.208 8.29655C128.855 9.41615 122.225 7.82861 116.526 8.36864C116.526 8.36864 114.918 8.33082 112.507 8.26572C88.9659 7.67404 89.7677 7.98609 85.2751 8.31578C84.6397 7.921 82.2314 8.86658 80.7415 8.19294C80.123 7.91552 75.7461 7.88857 74.7659 8.16172C74.5558 8.22185 73.0006 8.23461 70.7378 8.23743C68.4838 8.2403 65.5221 8.22764 62.5253 8.26502C61.0224 8.28369 59.5109 8.29671 58.0779 8.32698C56.6362 8.3516 55.2643 8.40457 54.0495 8.45851C51.6114 8.56634 49.7933 8.7059 49.2329 8.91463C44.7895 8.34006 29.0372 9.77859 24.789 10.1433Z" fill="#FFBA00"/>
      </svg>
      
  </span>
)

export const TitleUnderlineCovertionsTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="217" height="12" viewBox="0 0 217 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.1249 10.0819C35.9587 9.31303 4.19895 11.2449 1.93321 11.0913C-0.076861 10.8277 1.34764 4.95672 2.91164 4.03945C3.46616 3.71342 4.18919 3.62853 5.91696 3.72292C7.43231 3.80485 17.8759 3.37221 43.1413 2.41663C43.6361 2.58719 44.8866 1.95276 45.1828 2.16676C45.4087 2.291 49.0782 2.25218 54.533 2.15734C60.6537 2.04986 77.3162 1.67227 85.5339 1.52744C97.6621 1.30619 93.1001 1.33952 106.985 1.16259C109.889 1.12464 107.62 1.49036 111.035 1.34388C118.319 1.03696 134.241 1.34719 139.753 1.06845C144.175 0.844411 145.138 0.833586 149.387 1.07752C152.785 1.27155 176.769 1.34108 179.477 1.0004C180.13 0.898325 180.625 0.951625 180.61 1.07996C180.894 1.09287 196.857 1.53179 196.927 1.63832C197.04 1.76744 197.521 1.84299 197.989 1.76211C198.84 1.62217 216.194 1.61179 216.433 1.99296C217.106 3.09153 214.146 7.57382 212.057 8.63862C209.868 9.75307 199.111 8.14011 189.871 8.65834C189.871 8.65834 187.265 8.61437 183.355 8.54004C145.18 7.85824 146.481 8.17336 139.197 8.48586C138.166 8.08865 134.264 9.02503 131.846 8.34567C130.842 8.06588 123.744 8.02217 122.156 8.29157C121.816 8.3509 119.294 8.35771 115.625 8.35186C111.97 8.34611 107.167 8.32211 102.308 8.34803C99.8711 8.36094 97.4202 8.36818 95.0967 8.39296C92.7591 8.41207 90.5346 8.45979 88.5651 8.50908C84.6119 8.60758 81.6644 8.74018 80.7565 8.94678C73.5492 8.35518 48.0121 9.73345 41.1249 10.0819Z" fill="#FFBA00"/>
      </svg>
      
  </span>
)
