import React from "react"

const TitleUnderlineTestimony = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="407" height="12" viewBox="0 0 407 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.4856 10.4696C66.076 9.57395 4.90473 12.1215 1.39985 11.9955C-2.31221 11.7036 2.1768 4.67786 5.38816 3.5633C7.35641 2.8866 7.21829 3.37754 24.1902 2.8866C24.1902 2.8866 49.7775 2.1701 81.235 1.33418C81.235 1.33418 131.771 0.252793 201.35 0.000690749C206.823 -0.0192121 202.49 0.398747 208.93 0.252793C222.656 -0.0457491 252.525 0.518164 262.919 0.27933C271.258 0.0869364 273.054 0.0736683 281.013 0.438553C286.486 0.690656 332.412 1.31428 337.505 0.936124C338.748 0.843244 339.646 0.902952 339.629 1.05554C339.991 1.06881 370.085 1.9777 370.223 2.11702C370.43 2.27625 371.345 2.37576 372.226 2.29615C373.849 2.14356 406.394 2.6146 406.826 3.07899C408.069 4.40585 402.389 9.64693 398.4 10.861C394.274 12.1149 374.263 9.9123 356.583 10.2905C274.124 8.33334 274.763 8.83091 260.985 9.08965C259.086 8.60535 251.593 9.64693 247.138 8.81764C245.274 8.46603 231.876 8.33334 228.871 8.63188C228.233 8.69823 223.45 8.68496 216.544 8.63852C213.091 8.61861 209.103 8.58544 204.838 8.5589C199.14 8.5191 156.029 8.33997 150.625 9.10955C136.087 8.33997 89.3152 10.0052 75.4856 10.4696Z" fill="#FFBA00"/>
      </svg>
      
  </span>
)

export default TitleUnderlineTestimony
