import React from "react"

export const TitleUnderlineExperienceOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="215" height="9" viewBox="0 0 215 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.9719 9C35.5268 8.16452 2.97926 9.09727 0.613504 8.84548C-1.31791 8.50786 1.76866 2.5622 3.56145 1.68666C4.18986 1.37192 4.91991 1.32616 6.59257 1.46922C9.2448 1.73818 43.539 1.20598 43.539 1.20598C43.9918 1.38909 45.3502 0.77107 45.5998 0.994246C46.3853 1.51499 101.971 0.805402 106.712 0.77679C109.586 0.759622 107.313 1.11441 110.695 0.971352C117.903 0.685228 133.613 0.959909 139.075 0.633728C143.446 0.364771 144.388 0.353335 148.584 0.570789C152.872 0.765353 175.67 0.484949 178.276 0.0672084C178.914 -0.0472411 179.403 -0.00718032 179.403 0.124437C179.403 0.153049 195.474 0.250328 195.548 0.353332C195.668 0.484949 196.158 0.547892 196.611 0.456332C197.451 0.318993 214.557 -0.178864 214.825 0.204542C215.666 1.30326 213.309 5.98424 211.323 7.12301C209.289 8.3419 198.126 6.97424 189.153 7.70672L147.373 7.78111L138.419 8.2389C137.393 7.84405 133.447 8.83976 131.063 8.13017C130.055 7.84977 122.94 7.80971 121.369 8.10728C120.001 8.33618 83.5997 7.75821 79.8662 8.55363C73.2125 7.88982 46.2282 8.83977 39.9719 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineExperienceTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="134" height="9" viewBox="0 0 134 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.9127 9C22.1423 8.16452 1.85684 9.09727 0.38237 8.84548C-0.821395 8.50786 1.10233 2.5622 2.2197 1.68666C2.61135 1.37192 3.06636 1.32616 4.10886 1.46922C5.76188 1.73818 27.1359 1.20598 27.1359 1.20598C27.4181 1.38909 28.2648 0.77107 28.4203 0.994246C28.9099 1.51499 63.5541 0.805402 66.5088 0.77679C68.3001 0.759622 66.8832 1.11441 68.9912 0.971352C73.4838 0.685228 83.2752 0.959909 86.6791 0.633728C89.4034 0.364771 89.9909 0.353335 92.6058 0.570789C95.2783 0.765353 109.487 0.484949 111.112 0.0672084C111.509 -0.0472411 111.814 -0.00718032 111.814 0.124437C111.814 0.153049 121.83 0.250328 121.876 0.353332C121.951 0.484949 122.256 0.547892 122.539 0.456332C123.063 0.318993 133.724 -0.178864 133.891 0.204542C134.415 1.30326 132.946 5.98424 131.708 7.12301C130.441 8.3419 123.483 6.97424 117.891 7.70672L91.8513 7.78111L86.2702 8.2389C85.6308 7.84405 83.1715 8.83976 81.6855 8.13017C81.0577 7.84977 76.6228 7.80971 75.6436 8.10728C74.7912 8.33618 52.104 7.75821 49.7771 8.55363C45.6301 7.88982 28.812 8.83977 24.9127 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineExperienceThree = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="175" height="9" viewBox="0 0 175 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5352 9C28.9172 8.16452 2.42498 9.09727 0.499364 8.84548C-1.07272 8.50786 1.4396 2.5622 2.89886 1.68666C3.41035 1.37192 4.00458 1.32616 5.36605 1.46922C7.52484 1.73818 35.4387 1.20598 35.4387 1.20598C35.8073 1.38909 36.913 0.77107 37.1161 0.994246C37.7554 1.51499 82.9998 0.805402 86.8586 0.77679C89.1979 0.759622 87.3475 1.11441 90.1005 0.971352C95.9676 0.685228 108.755 0.959909 113.2 0.633728C116.758 0.364771 117.525 0.353335 120.94 0.570789C124.431 0.765353 142.987 0.484949 145.108 0.0672084C145.627 -0.0472411 146.026 -0.00718032 146.026 0.124437C146.026 0.153049 159.107 0.250328 159.167 0.353332C159.265 0.484949 159.663 0.547892 160.032 0.456332C160.716 0.318993 174.639 -0.178864 174.858 0.204542C175.542 1.30326 173.624 5.98424 172.007 7.12301C170.352 8.3419 161.265 6.97424 153.962 7.70672L119.955 7.78111L112.666 8.2389C111.831 7.84405 108.619 8.83976 106.679 8.13017C105.859 7.84977 100.067 7.80971 98.7883 8.10728C97.6751 8.33618 68.0462 7.75821 65.0074 8.55363C59.5916 7.88982 37.6276 8.83977 32.5352 9Z" fill="#FFA700" />
    </svg>
  </span>
)
