import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="147" height="8" viewBox="0 0 147 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.3296 8C24.2904 7.25735 2.03698 8.08646 0.419466 7.86265C-0.901083 7.56254 1.20927 2.27751 2.43504 1.49925C2.86469 1.21949 3.36385 1.17881 4.50748 1.30597C6.32086 1.54505 29.7685 1.07198 29.7685 1.07198C30.0781 1.23475 31.0069 0.685396 31.1775 0.883775C31.7146 1.34666 69.7198 0.715913 72.9612 0.69048C74.9262 0.67522 73.3719 0.99059 75.6844 0.863424C80.6128 0.609091 91.3541 0.853252 95.0883 0.563314C98.0769 0.324241 98.7214 0.314075 101.59 0.507368C104.522 0.680314 120.109 0.431066 121.891 0.0597408C122.327 -0.0419921 122.662 -0.00638251 122.662 0.11061C122.662 0.136044 133.65 0.222513 133.7 0.314073C133.782 0.431066 134.117 0.487015 134.427 0.405629C135.002 0.283549 146.697 -0.15899 146.88 0.181815C147.455 1.15845 145.844 5.31932 144.486 6.33157C143.096 7.41502 135.463 6.19932 129.328 6.85041L100.762 6.91654L94.6397 7.32347C93.9383 6.97249 91.2404 7.85756 89.6102 7.22682C88.9215 6.97757 84.0563 6.94196 82.9822 7.20647C82.0471 7.40994 57.1588 6.89618 54.6062 7.60323C50.0569 7.01318 31.6072 7.85757 27.3296 8Z" fill="#FFA700"/>
      </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="242" height="8" viewBox="0 0 242 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.9916 8C39.9883 7.25735 3.3534 8.08646 0.690549 7.86265C-1.48342 7.56254 1.99077 2.27751 4.00871 1.49925C4.71602 1.21949 5.53776 1.17881 7.42048 1.30597C10.4058 1.54505 49.0066 1.07198 49.0066 1.07198C49.5163 1.23475 51.0454 0.685396 51.3262 0.883775C52.2104 1.34666 114.777 0.715913 120.113 0.69048C123.348 0.67522 120.789 0.99059 124.596 0.863424C132.709 0.609091 150.392 0.853252 156.54 0.563314C161.46 0.324241 162.521 0.314075 167.243 0.507368C172.07 0.680314 197.731 0.431066 200.664 0.0597408C201.382 -0.0419921 201.933 -0.00638251 201.933 0.11061C201.933 0.136044 220.022 0.222513 220.105 0.314073C220.24 0.431066 220.791 0.487015 221.301 0.405629C222.248 0.283549 241.501 -0.15899 241.803 0.181815C242.75 1.15845 240.097 5.31932 237.861 6.33157C235.572 7.41502 223.007 6.19932 212.907 6.85041L165.881 6.91654L155.801 7.32347C154.647 6.97249 150.205 7.85756 147.522 7.22682C146.388 6.97757 138.378 6.94196 136.61 7.20647C135.071 7.40994 94.0982 6.89618 89.8959 7.60323C82.4067 7.01318 52.0336 7.85757 44.9916 8Z" fill="#FFA700" />
    </svg>
  </span>
)

