import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="241" height="11" viewBox="0 0 241 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.6167 10.1515C39.6583 9.20638 3.32505 10.1193 0.685336 9.83058C-1.46924 9.44812 1.99844 2.85358 4.00287 1.88756C4.70549 1.54024 5.52055 1.49216 7.38704 1.65746C10.3464 1.96636 48.6277 1.5051 48.6277 1.5051C49.1325 1.71028 50.6511 1.02874 50.9288 1.27766C51.8036 1.85924 113.851 1.28164 119.143 1.26783C122.351 1.25965 119.812 1.64524 123.588 1.49911C131.635 1.20854 149.17 1.57332 155.267 1.23161C160.147 0.949352 161.199 0.940219 165.882 1.19775C170.667 1.43019 196.115 1.2051 199.026 0.750829C199.738 0.626083 200.285 0.672452 200.284 0.818692C200.284 0.850483 218.222 1.01952 218.304 1.13425C218.437 1.28095 218.984 1.35274 219.489 1.25273C220.429 1.10332 239.524 0.615022 239.821 1.04204C240.756 2.26602 238.108 7.45814 235.886 8.7159C233.612 10.0625 221.157 8.50055 211.138 9.28037L164.503 9.20458L154.506 9.67927C153.363 9.23666 148.954 10.328 146.296 9.53056C145.172 9.21518 137.23 9.14368 135.475 9.46835C133.948 9.7175 93.3188 8.93725 89.1485 9.80689C81.7242 9.0441 51.6006 9.99724 44.6167 10.1515Z" fill="#FFBA00" />
    </svg>
  </span>
)

export default titleUnderline
